const styles = {
	wrapper: {
		py: [20, 30],
	},
	grid: {
		gridGap: [20, 30],
		gridTemplateColumns: '1fr',
		'@media only screen and (min-width: 568px)': {
			gridTemplateColumns: '1fr 1fr',
		},
		'@media only screen and (min-width: 992px)': {
			gridTemplateColumns: '1fr 1fr 1fr',
		},
	},
	notFound: {
		textAlign: 'center',
		paddingTop: ['25px', '50px'],
		paddingBottom: ['25px', '50px'],
		img: {
			marginBottom: 30,
		},
		h3: {
			fontSize: 2,
			color: 'primary',
			marginBottom: [12, 15],
		},
	},	
};

export default styles;
