import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import SEO from '../components/seo';
import PrimaryLayout from '../components/layout/primary/primary';
//import CategoryBlocks from '../components/category-blocks/primary/primary';
import OfferBlocks from '../components/offer-blocks/primary/primary';


const offerPageStaticQuery = graphql`
	query {
		prismic {
			allOfferss {
				edges {
					node {
						offer_block {
							offer_slug
							offer_title
							image
							imageSharp {
								childImageSharp {
									fluid {
										...GatsbyImageSharpFluid_withWebp_tracedSVG
									}
								}
							}
						}
					}
				}
			}			
		}
	}
`;

const OfferPage: React.FC<{}> = () => (
	<StaticQuery<GatsbyTypes.Query>
		query={`${offerPageStaticQuery}`}
		render={(data) => {
			//console.log(JSON.stringify(data))
			const node = get(data, 'prismic.allOfferss.edges[0].node');
			const offerBlock = node.offer_block;
			
			return (
				<PrimaryLayout pathPrefix={'/#'}>
					<SEO title="Home" />
					{/* End of home seo */}					
					<OfferBlocks offerBlock={offerBlock} />
				</PrimaryLayout>
			);
		}}
	/>
);

export default OfferPage;
