/** @jsx jsx */
import { Grid, Box, jsx, Text } from 'theme-ui';
import PrimaryCard from '../../offer-card/primary/primary';
import styles from './primary.style';

const getOfferData = (edges: any): any => {
	const data: any = [];
	edges.forEach((offer: any, index: number) => {
		data.push({
			id: `offer-${index}`,
			title: offer.offer_title,
			thumbnail: offer.imageSharp.childImageSharp.fluid,
			path: offer.offer_slug,
		});
	});
	return data;
};

const OfferBlocks = ({ offerBlock }: any) => {
	const offers = getOfferData(offerBlock);
	return (
		<Box sx={styles.wrapper}>
			{
			offers.length > 0 ? (						
			<Grid sx={styles.grid} id="categories">
				{offers.map((offer: any): any => {
				return(
					<PrimaryCard
						key={`offer-block--key${offer.id}`}
						path={offer.path}
						thumbnail={offer.thumbnail}
						title={offer.title}
					/>		
				)})}
			</Grid>	
				) :
				(
				<Box sx={styles.notFound}>
					<Text as="h3">Sorry - No ongoing offers!</Text>
					<Text as="p">New offers will be available here once launched</Text>
				</Box>
				)}
		</Box>
	);
};

export default OfferBlocks;
